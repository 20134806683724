import type { FormLabelTranslationsObject } from "../../translation-keys/form-label-keys.js";

const FormLabels: FormLabelTranslationsObject = {
eclipse_FirstName: "이름",
eclipse_LastName: "성",
eclipse_Email: "이메일",
eclipse_Company: "회사 이름",
eclipse_Country: "국가",
eclipse_Phone: "전화",
eclipse_Tier__c: "회사 규모",
eclipse_Industry: "산업",
eclipse_Department__c: "부서",
eclipse_contactRequested: "I want someone to call me.",
eclipse_Webform_Comments_recent_value__c: "어떻게 도와드릴까요?",
eclipse_Latest_Solution_Interest__c: "Main Solution Interest",
eclipse_Email_Opt_In__c: `다른분의 연락을 요청드립니다. {privacy-policy-link-slot}`,
privacyStatement: `우리는 귀하의 데이터를 소중하게 생각합니다. {privacy-policy-link-slot}.`,
privacyPolicyLinkText: "데이터 처리 및 보호 과정을 확인하려면 다음을 읽으십시오.",
eclipse_Entity: "",
eclipse_Partner_Opt_in_Processing__c: "I’d like to receive more information on this topic from the MasterControl Partner(s) affiliated with this event.",
eclipse_NonBusinessEmail: "이메일",
privacyNewsletterOptIn: ""
};

export default FormLabels;